import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0];

export const dictionary = {
		"/(app)": [5,[2]],
		"/(app)/about": [6,[2]],
		"/(app)/admin/events": [7,[2]],
		"/(app)/event/new": [13,[2]],
		"/(app)/event/[id]": [~8,[2],[,3]],
		"/(app)/event/[id]/destroy": [11,[2],[,3]],
		"/(app)/event/[id]/edit": [12,[2],[,3]],
		"/(app)/event/[id]/[slug]": [9,[2],[,3,4]],
		"/(app)/event/[id]/[slug]/embed": [10],
		"/(app)/login": [14,[2]],
		"/(app)/logout": [15,[2]],
		"/(app)/privacy-policy": [16,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';